import React, { useEffect } from "react"
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    IconButton,
    Typography,
    CircularProgress,
} from "@material-ui/core"
import { useItemsContext } from "../../context/providers/ItemsProvider"
import DeleteIcon from "@material-ui/icons/Delete"
import EditIcon from "@material-ui/icons/Create"
import { useStyles } from "./styles"
import { FormattedMessage } from "react-intl"

const PaginatedItemTable = ({ searchQuery }) => {
    const classes = useStyles()

    const {
        editItem,
        deleteItem,
        getPaginatedItemsData,
        tableData,
        deleting,
        setPage,
        rowsPerPage,
        setRowsPerPage,
    } = useItemsContext()

    useEffect(() => {
        const fetchData = async () => {
            await getPaginatedItemsData(1, 10, searchQuery)
        }
        fetchData()
    }, [])

    const handleChangePage = async (event, newPage) => {
        await getPaginatedItemsData(newPage + 1, rowsPerPage, searchQuery)
        setPage(newPage + 1)
    }

    const handleChangeRowsPerPage = async event => {
        const newPageSize = event.target.value
        await getPaginatedItemsData(1, newPageSize, searchQuery)
        setRowsPerPage(newPageSize)
        setPage(1)
    }

    const handleEdit = async itemId => {
        await editItem(itemId)
    }

    const handleDelete = async item => {
        await deleteItem(item)
    }

    return (
        <Paper className={classes.items_table}>
            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow className={classes.table_row}>
                            <TableCell width={500} align="left">
                                <FormattedMessage
                                    id="items.description"
                                    defaultMessage="Description"
                                />
                            </TableCell>
                            <TableCell width={100} align="center">
                                <FormattedMessage
                                    id="items.freightClass"
                                    defaultMessage="Freight Class"
                                />
                            </TableCell>
                            <TableCell width={150} align="center">
                                <FormattedMessage
                                    id="items.packageType"
                                    defaultMessage="Package Type"
                                />
                            </TableCell>
                            <TableCell width={150} align="center">
                                <FormattedMessage
                                    id="items.dimensions"
                                    defaultMessage="Dimensions"
                                />
                            </TableCell>
                            <TableCell width={75} align="center">
                                <FormattedMessage
                                    id="items.weight"
                                    defaultMessage="Weight"
                                />
                            </TableCell>
                            <TableCell width={75} align="center">
                                <FormattedMessage
                                    id="items.pieces"
                                    defaultMessage="Pieces"
                                />
                            </TableCell>
                            <TableCell width={150} align="center">
                                <FormattedMessage
                                    id="items.nmfc"
                                    defaultMessage="NMFC"
                                />
                            </TableCell>
                            <TableCell width={150} align="center">
                                <FormattedMessage
                                    id="items.hazmat"
                                    defaultMessage="Hazmat Details"
                                />
                            </TableCell>

                            <TableCell width={100} align="center" />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tableData.content.map(item => (
                            <TableRow
                                className={classes.table_row}
                                key={item._id}
                            >
                                <TableCell>{item?.description}</TableCell>
                                <TableCell align="center">
                                    {item?.freightClass}
                                </TableCell>
                                <TableCell align="center">
                                    {item?.packageType}
                                </TableCell>
                                <TableCell align="center">
                                    {`${item?.length}x${item?.width}x${
                                        item?.height
                                    } ${
                                        item.measurementSystem === "IMPERIAL"
                                            ? "in"
                                            : "cm"
                                    }`}
                                </TableCell>
                                <TableCell align="center">
                                    {`${item?.weight} ${
                                        item.measurementSystem === "IMPERIAL"
                                            ? "lb"
                                            : "kg"
                                    }
                                        `}
                                </TableCell>
                                <TableCell align="center">
                                    {item?.pieces}
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2">
                                        {item.nmfc1 ?? ""}
                                        {item.nmfc2 ? `-${item.nmfc2}` : null}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Typography variant="body2">
                                        {item.unNumber} {item.hazClass ?? ""}{" "}
                                        {item.pkgGrp ?? ""}{" "}
                                        {item.hazContainer ?? ""}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <Box
                                        sx={{
                                            display: "flex",
                                            minWidth: "60px",
                                            justifyContent: "flex-end",
                                        }}
                                    >
                                        {deleting === item._id ? (
                                            <CircularProgress size={30} />
                                        ) : (
                                            <Box>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEdit(item._id)
                                                    }}
                                                    size="small"
                                                >
                                                    <EditIcon />
                                                </IconButton>

                                                <IconButton
                                                    onClick={() => {
                                                        handleDelete(item)
                                                    }}
                                                    size="small"
                                                >
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Box>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={tableData.totalElements}
                page={tableData.pageable.pageNumber}
                onPageChange={handleChangePage}
                rowsPerPage={tableData.pageable.pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
            />
        </Paper>
    )
}

export default PaginatedItemTable
